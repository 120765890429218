import { trpc } from '@/webapp/src/lib/trpc.js'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { getRandomUuid } from 'svag-utils'

export const getClientToken = () => {
  const clientToken = Cookies.get('clientToken') || getRandomUuid()
  Cookies.set('clientToken', clientToken, { expires: 9999 })
  return clientToken
}

export const useConversionClientData = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const searchParamsObject = Object.fromEntries(searchParams.entries())
  const gclid = searchParamsObject.gclid
  const fbclid = searchParamsObject.fbclid
  const customclid = searchParamsObject.customclid
  const mediaSource = searchParamsObject.media_source || searchParamsObject.utm_source || searchParamsObject.source
  const channel = searchParamsObject.channel || searchParamsObject.utm_medium || searchParamsObject.medium
  const campaign =
    searchParamsObject.campaign ||
    searchParamsObject.utm_campaign ||
    searchParamsObject.utm_c ||
    searchParamsObject.name
  const campaignId = searchParamsObject.campaign_id || searchParamsObject.utm_campaign_id || searchParamsObject.utm_cid
  const adset = searchParamsObject.adset || searchParamsObject.utm_adset
  const adsetId = searchParamsObject.adset_id || searchParamsObject.utm_adset_id
  const ad = searchParamsObject.ad || searchParamsObject.utm_ad
  const adId = searchParamsObject.ad_id || searchParamsObject.utm_ad_id
  const siteId = searchParamsObject.site_id || searchParamsObject.utm_site_id
  const keywords = searchParamsObject.keywords || searchParamsObject.utm_keywords
  const userAgent = navigator.userAgent
  return {
    gclid,
    fbclid,
    customclid,
    mediaSource,
    channel,
    campaign,
    campaignId,
    adset,
    adsetId,
    ad,
    adId,
    siteId,
    keywords,
    userAgent,
    rawData: searchParamsObject,
  }
}

export const useCreateConversionEvent = () => {
  const clientData = useConversionClientData()
  const createConversionEventNative = trpc.createConversionEvent.useMutation()
  const createConversionEvent = async ({ eventName }: { eventName: 'contact' | 'visit' }) => {
    return await createConversionEventNative.mutateAsync({
      ...clientData,
      clientToken: getClientToken(),
      eventName,
    })
  }
  return { createConversionEvent }
}

export const useCreateVisitConversionEvent = () => {
  const { createConversionEvent } = useCreateConversionEvent()
  useEffect(() => {
    // eslint-disable-next-line no-console
    createConversionEvent({ eventName: 'visit' }).catch(console.error)
  }, [])
}
